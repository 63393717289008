import {
  mdiAccount,
  mdiBriefcase,
  mdiCog,
  mdiFamilyTree,
  mdiFileDocument,
  mdiHome,
  mdiListBox,
  mdiLogout,
  mdiMonitor,
  mdiNewspaperVariant,
  mdiNoteOutline,
  mdiPlayBoxOutline,
  mdiPlaylistPlay,
  mdiPoll,
  mdiRayVertex,
} from '@mdi/js';
import route from 'ziggy-js';

export const navItems = (user) => [
  {
    title: 'Dashboard',
    href: route('dashboard'),
    icon: mdiHome,
    active: 'dashboard',
  },
  {
    title: 'Plans',
    href: route('review-templates.index'),
    icon: mdiFileDocument,
    active: 'review-templates.*',
    filter: () => ['admin'].includes(user.role.value),
  },
  {
    title: 'Plan Items',
    href: route('input-groups.index'),
    icon: mdiListBox,
    active: 'input-groups.*',
    filter: () => ['admin'].includes(user.role.value),
  },
  {
    title: 'Team Structure',
    href: route('structure'),
    icon: mdiFamilyTree,
    active: 'structure',
    filter: () => ['admin'].includes(user.role.value),
  },
  {
    title: 'Reports',
    href: route('reports.index'),
    icon: mdiPoll,
    active: 'reports.*',
    filter: () => ['admin', 'manager'].includes(user.role.value),
  },
  {
    title: 'Job Vacancies',
    href: route('job-vacancies.index'),
    icon: mdiBriefcase,
    active: 'job-vacancies.*',
    filter: () => ['admin'].includes(user.role.value),
  },
  {
    title: 'Slides',
    href: route('slides.index'),
    icon: mdiPlayBoxOutline,
    active: 'slides.*',
    filter: () => ['admin', 'signage'].includes(user.role.value),
  },
  {
    title: 'Playlists',
    href: route('playlists.index'),
    icon: mdiPlaylistPlay,
    active: 'playlists.*',
    filter: () => ['admin', 'signage'].includes(user.role.value),
  },
  {
    title: 'Screens',
    href: route('screens.index'),
    icon: mdiMonitor,
    active: 'screens.*',
    filter: () => ['admin', 'signage'].includes(user.role.value),
  },
    {
        title: 'Articles',
        href: route('articles.index'),
        icon: mdiNewspaperVariant,
        active: 'articles.*',
        filter: () => ['admin', 'signage'].includes(user.role.value),
    },
    {
        title: 'Memos',
        href: route('memos.index'),
        icon: mdiNoteOutline,
        active: 'memos.*',
        filter: () => ['admin', 'signage'].includes(user.role.value),
  },
  {
    title: 'Settings',
    icon: mdiCog,
    sub: [
      // {
      //   title: 'Strategic Focuses',
      //   href: route('focuses.index'),
      //   icon: mdiBullseye,
      //   active: 'focuses.*',
      // },
      {
        title: 'Value Thresholds',
        href: route('thresholds.index'),
        icon: mdiRayVertex,
        active: 'thresholds.*',
      },
    ],
    filter: () => ['admin'].includes(user.role.value),
  },
  {
    title: 'Users',
    href: route('users.index'),
    icon: mdiAccount,
    active: 'users.*',
    filter: () => ['admin'].includes(user.role.value),
  },
].filter(item => typeof item.filter !== 'function' || item.filter());

export const accountMenuItems = [
  {
    title: 'Log out',
    href: route('logout'),
    icon: mdiLogout,
  },
];